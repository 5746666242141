<template>
  <div class="container is-fluid mt-1 mb-1">
    <inbox-mobile v-if="isMobile"></inbox-mobile>
    <div v-else class="box is-radius-large inbox-container is-paddingless overflow-hidden">
      <div class="columns is-mobile is-gapless has-fullheight">
        <div class="column is-narrow right-border">
          <inbox-contact-list :thread-id="threadId($route)" class="inbox-contact-list"></inbox-contact-list>
        </div>
        <div class="column">
          <router-view class="has-fullheight"></router-view>
          <div v-if="placeholderVisible" class="has-fullheight is-flex is-justify-content-center is-align-items-center">
            <div class="has-text-secondary">{{ $t("inbox.intro_placeholder") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InboxContactList from "@/web/components/inbox/InboxContactList";
import InboxMobile from "@/web/components/inbox/InboxMobile";
import {Constants} from "@/web/constants";
import AuthorizedRouteMixin from "@/web/mixins/AuthorizedRouteMixin";

export default {
  name: "Inbox",

  mixins: [AuthorizedRouteMixin],

  components: {InboxContactList, InboxMobile},

  props: ["threadId"],

  data() {
    return {
      isMobile: false,
    };
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, {passive: true});
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, {passive: true});
    }
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
  },

  computed: {
    placeholderVisible() {
      return this.$route.name === Constants.ROUTE_INBOX;
    },
  },
};
</script>

<style scoped>
.overflow-hidden {
  overflow: hidden;
}

.inbox-container {
  height: 70vh;
}

.inbox-contact-list {
  max-width: 350px;
  width: 350px;
}

.right-border {
  border-right: 1px solid #e0e0e0;
}
</style>
